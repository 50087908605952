import './App.css';
import { Flex, Box } from '@chakra-ui/react'
import sob_gif from './assets/SOB.gif'
import ParticlesBackground from "./ParticlesBackground"
import shitter_on_base_gif from './assets/shitter_on_base.gif'
import buy_gif from './assets/buy.gif'
import x_gif from './assets/x.gif'
import tg_gif from './assets/tg.gif'

function App() {

  function seeSob() {
    const url = 'https://dexscreener.com/base/0x505eb6f187096e45bb21635fa2c504d8998f11ce'
    window.open(url, '_blank')
  }

  function buySob() {
    const url = 'https://app.uniswap.org/explore/tokens/base/0x326a283826275b0386bd2d9294baa41670db7845'
    window.open(url, '_blank')
  }

  function seeX() {
    const url = 'https://twitter.com/baseshitter'
    window.open(url, '_blank')
  }

  function seeTG() {
    const url = 'https://t.me/shitteronbase'
    window.open(url, '_blank')
  }

  return (

    <div className="overlay">
            <ParticlesBackground />
       <div className="App">
            <Flex justifyContent="center" alignItems="center" paddingLeft="20px" paddingTop="130px">
              <img src={shitter_on_base_gif} alt=''/>
            </Flex>
            <Flex justifyContent="center" alignItems="center" paddingTop="100px">
              <img src={sob_gif} onClick={seeSob} alt=''/>
            </Flex>
            <Flex justifyContent="center" alignItems="center" paddingLeft="22px" paddingTop="100px">
              <img src={buy_gif} onClick={buySob} alt=''/>
            </Flex>
            <Flex justifyContent="center" alignItems="center" paddingLeft="33px" paddingTop="50px">
              <img src={x_gif} onClick={seeX} alt='' style={{padding: "50px"}}/>
              <img src={tg_gif} onClick={seeTG} alt='' style={{padding: "50px"}}/>
            </Flex>
        </div>
    </div>
  );
}

export default App;