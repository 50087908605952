
import { useEffect, useMemo, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";

const ParticlesBackground = () => {
  const [init, setInit] = useState(false);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
    console.log(container);
  };

  const options = useMemo(
    () => ({
      fpsLimit: 120,
      particles: {
        move: {
          direction: "random",
          enable: true,
          outModes: {
            default: "out",
          },
          speed: 9,
          random: true,
          straight: true,
          angle: 0,
        },
        number: {
          value: 444,
        },
        shape: {
          type: "images",
          options: {
            images: [
              {
                src: "https://raw.githubusercontent.com/BaseShitter/Website/main/assets/shitter.png",
                width: 200, 
                height: 200
              },
            ]
          }
        },
        size: {
          value: 17,
        },
      },      
      }),
    [],
  );

  if (init) {
    return (
      <Particles
        id="tsparticles"
        particlesLoaded={particlesLoaded}
        options={options}
      />
    );
  }

  return <></>;
};

export default ParticlesBackground;